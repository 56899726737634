import React from 'react';
import {Link,graphql, useStaticQuery } from "gatsby"
import countries from '../../assets/images/countries.png'
import experince from '../../assets/images/experince.png'
import Respurce from '../../assets/images/Respurce.png'
import project from '../../assets/images/project.png'


const query = graphql`
    {
        strapiCompanyHighlight {
            team_members
            projects
            clients
            year_of_experience
          }
    }
`


function FunfactComponent() {
    const data = useStaticQuery(query)
    const companyHighlight = data.strapiCompanyHighlight

  return (
    <section className='why-choose-sarshtasoft'>
    <h1>WHY CHOOSE <span>SRASHTASOFT</span></h1>
    <p> Strength </p>
    <div className='container'>
    <div className='row'>
      <div className='col-md-3'>
        <div className='icon-div'>
        <div class="icon"><img class="iconss-image" src={countries} alt="project completed" />                 
        </div>
        <span>{companyHighlight.year_of_experience}</span>
        Our Experience
      </div>
      </div>
      <div className='col-md-3'>
      <div className='icon-div'>
        <div class="icon"><img class="iconss-image" src={experince} alt="project completed" />                 
        </div>
        <span>{companyHighlight.team_members}</span>
        Our Team Member
      </div>
      </div>
      <div className='col-md-3'>
      <div className='icon-div'>
        <div class="icon"><img class="iconss-image" src={project} alt="project completed" />                 
        </div>
        <span>{companyHighlight.projects}</span>
       Our Project
      </div>
      </div>
      <div className='col-md-3'>
      <div className='icon-div'>
        <div class="icon"><img  class="iconss-image" src={Respurce} alt="project completed" />                 
        </div>
        <span>{companyHighlight.clients}</span>
        Our Client
      </div>
      </div>
    </div>
    </div>
  </section>
  );
}

export default FunfactComponent;
